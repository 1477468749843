$white:#ffffff;
$green:#52b945;
$red:#d94242;
$graphite:#5b5d62;
$dark_blue:#16233a;
$medium_blue:#102059;
$blue:#007ef5;
$light_blue:#7daff8;
$grey:#e8e9ef;
//$font_color:#5b627e;
$font_color:#4b5063;

$montserrat:'Montserrat', sans-serif;
$lato:'Lato', sans-serif;

body{
	font-family: $lato;
	ul{
		list-style: none;
		margin: 0;
		padding: 0;
	}
	h1,h2,h3,h4,h5,h6,p{
		margin: 0;
		padding: 0;
		font-weight: 400;
	}
	a{
		text-decoration: none;
		&:hover{
			text-decoration: none;
		}
	}
}
*{
    &:focus{
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: 8px !important;
        outline-color: coral !important;
        position: relative;
        z-index: 9999;
    }
}

div.page{
	transition: 0.3s all ease;
}

.more1{
	font-size: 14px;
	display: inline-block;
	padding-right: 38px;
	background-repeat: no-repeat;
	background-position: center right;
	background-image: url('./../img/green_arrow.png');
	font-family: $montserrat;
	font-weight: 600;
	color: $white;
}

@mixin fill{
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}

@mixin blueGradient{
background: rgba(29,180,255,1);
background: -moz-linear-gradient(-45deg, rgba(29,180,255,1) 0%, rgba(15,152,250,1) 35%, rgba(0,127,245,1) 75%, rgba(0,127,245,1) 100%);
background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(29,180,255,1)), color-stop(35%, rgba(15,152,250,1)), color-stop(75%, rgba(0,127,245,1)), color-stop(100%, rgba(0,127,245,1)));
background: -webkit-linear-gradient(-45deg, rgba(29,180,255,1) 0%, rgba(15,152,250,1) 35%, rgba(0,127,245,1) 75%, rgba(0,127,245,1) 100%);
background: -o-linear-gradient(-45deg, rgba(29,180,255,1) 0%, rgba(15,152,250,1) 35%, rgba(0,127,245,1) 75%, rgba(0,127,245,1) 100%);
background: -ms-linear-gradient(-45deg, rgba(29,180,255,1) 0%, rgba(15,152,250,1) 35%, rgba(0,127,245,1) 75%, rgba(0,127,245,1) 100%);
background: linear-gradient(135deg, rgba(29,180,255,1) 0%, rgba(15,152,250,1) 35%, rgba(0,127,245,1) 75%, rgba(0,127,245,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1db4ff', endColorstr='#007ff5', GradientType=1 );
}

.skiplinks{
    z-index: 99999999;
    > a{
        background: rgba(0,0,0,0.9);
        padding: 15px;
        font-size: 15px;
        line-height: 20px;
        color: $white;
        position: absolute;
        left: -100%;
        white-space: nowrap;
        &:focus{
            left: 0;
        }
    }
}

a.all_click{
	@include fill;
}

div.default_font{
	font-size: 16px;
	line-height: 29px;
	h1{
		font-family: $montserrat;
	}
	h2{
		font-size: 36px;
		line-height: 42px;
		font-family: $montserrat;
	}
	h3{
		font-size: 30px;
		line-height: 36px;
		font-family: $montserrat;
	}
	h4{
		font-size: 26px;
		font-family: $montserrat;
	}
	h5{
		font-size: 22px;
		line-height: 30px;
		font-family: $montserrat;
	}
	h6{
		font-size: 19px;
		line-height: 26px;
		font-family: $montserrat;
	}
}

div.text_desc{
	div.default_font{
		h1,h2,h3,h4,h5,h6,p,a,ul,ol{
			padding-bottom: 20px;
			&:last-child{
				padding-bottom: 0;
			}
		}
		img{
			margin-bottom: 20px;
			max-width: 100%;
			&:last-child{
				margin-bottom: 0;
			}
		}
		ul{
			li{
				padding-left: 15px;
				background-repeat: no-repeat;
				background-position: left top 13px;
				background-image: url('./../img/green_mark.png');
			}
		}
		ol{
			padding-left: 20px;
		}
		table{
			border-top: 1px solid #172c7b;
			tr{
				&:first-child{
					background-color: #172c7b;
					color: $white;
				}
				&:nth-child(even){
					background-color: #f2f3f8;
				}
				td{
					padding: 20px;
				}
			}
		}
	}
}

button{
    padding: 0;
    border: 0;
    background-color: transparent;
}

.rwd_button{
    display: none;
	&.open{
		right: 15px;
		> span{
			background-color: $white;
		}
	}
}

div.rwd_menu{
	overflow-y: scroll;
	z-index: 10;
	width: 255px;
	height: 100%;
	position: fixed;
	top: 0;
	right: -255px;
	background-color: #172c7b;
	transition: 0.3s all ease;
	div.holder{
		margin:45px 0 20px 0;
		width: 100%;
		position: relative;
		input.input{
			width: 100%;
			height: 30px;
			padding-left: 15px;
			padding-right: 20%;
			//outline: none;
			font-family: $lato;
			font-size: 13px;
			border: none;
			background-color: $white;
			border-radius: 20px;
		}
		input.submit{
			width: 20%;
			position: absolute;
			top: 0;
			right: 0;
			height: 100%;
			background-repeat: no-repeat;
			background-position: center;
			background-image: url('./../img/green_search_icon.png');
			//outline: none;
			background-color: transparent;
			border: none;
            text-indent: 999px;
		}
	}
	nav.menu{
		> ul{
			> li{
				a{
					color: $white;
					font-family: $montserrat;
					font-size: 13px;
					line-height: 23px;
					padding-left: 15px;
					background-position: left top 8px;
					background-repeat: no-repeat;
					background-image: url('./../img/green_mark.png');
					display: inline-block;
					margin-bottom: 8px;
				}
			}
		}
	}
	div.menu_box{
		padding: 0 15px;
	}
}

header.header{
	position: relative;
	z-index: 15;
	div.container{
		//padding-left: 0;
		//padding-right: 0;
	}
	div.header_top{
		border-bottom: 1px solid #d9dfe5;
		ul{
			li{
				display: inline-block;
				vertical-align: top;
				> div{
					height: 61px;
				}
				&.header_calendar{
					width: 82%;
					> div{
						padding: 25px 31px 0 31px;
						display: inline-block;
						vertical-align: top;
						font-size: 15px;
						line-height: 15px;
						color: #6c7390;
						font-family: $montserrat;
						p{
							font-weight: 300;
							strong{
								font-weight: 500;
							}
						}
						&:first-child{
							border-right: 1px solid #d9dfe5;
							padding-left: 0;
						}
						&:last-child{
							padding-right: 0;
						}
					}
				}
				&.ue{
					width: 18%;
					> div{
						text-align: right;
						padding-top: 12px;
						padding-left: 37px;
						border-left: 1px solid #d9dfe5;
					}
				}
			}
		}
	}
	div.header_middle{
		padding: 16px 0 16px 0;
		div.logo_box{
			img{
				max-width: 100%;
				height: auto;
			}
		}
		div.short_menu{
			padding-top: 15px;
			nav.menu{
				text-align: right;
				> ul{
					> li{
						/*&:nth-child(1){
							margin-right: 17px;
						}
						&:last-child{
							a{
								padding-left: 37px;
							}
							margin-right: 0;
							a{
								padding-right: 0;
								margin-right: 0;
								&:after{
									display: none;
								}
							}
						}*/
						&:first-child{
							a{
								padding-left: 0;
							}
						}
						&:last-child{
							a{
								padding-right: 0;
								&:after{
									border-right: 0;
								}
							}
						}
						display: inline-block;
						vertical-align: middle;
						white-space: nowrap;
						a, button{
							color: #27274d;
							padding-right: 21px;
							padding-left: 21px;
							position: relative;
							font-size: 18px;
							line-height: 17px;
							display: inline-block;
							&:after{
								content: '';
								display: block;
								position: absolute;
								top: 0;
								right: 0;
								height: 100%;
								border-right: 2px solid $light_blue;
							}
						}
						&.bip{
							background-position: left center;
							background-repeat: no-repeat;
							background-image: url('./../img/bip_logo.png');
							padding-left: 34px;
							padding-top: 12px;
							padding-bottom: 12px;
							margin-left: 15px;
						}
						&.availability{
							background-image: url(./../img/eye_icon.png);
							background-repeat: no-repeat;
							background-position: left 10px center;
							button{
								padding-left: 54px;
								padding-top: 12px;
								padding-bottom: 12px;
                                padding-right: 0;
                                &:after{
                                    display: none;
                                }
							}
						}
					}
				}
			}
		}
		form{
			div.holder{
				//margin-top: 20px;
				position: relative;
				input.input{
					width: 100%;
					height: 75px;
					border-radius: 50px;
					border: 2px solid #edeef3;
					padding-left:30px;
					padding-right: 20%;
					font-size: 17px;
					font-family: $montserrat;
					color: #6c7390;
					font-weight: 300;
					//outline: none;
				}
				input.submit{
					position: absolute;
					top: 0;
					right: 5%;
					height: 100%;
					width: 20%;
					background-repeat: no-repeat;
					background-position: center;
					background-image: url('./../img/green_search_icon.png');
					border: none;
					//outline: none;
					background-color: transparent;
                    text-indent: 9999px;
				}
			}
		}
	}
	div.header_bottom{
		div.menu_box{
			position: relative;
			z-index: 3;
			width: 100%;
			nav.menu{
				background-color: #172c7b;
				> ul{
					> li{
						border-top: 2px solid #172c7b;
						transition: 0.3s all ease;
						&:hover{
							border-top: 2px solid #007ef5;
							background-color: #102059;
							> div{
								display: block;
							}
						}
						display: inline-block;
						vertical-align: top;
						&:last-child{
							> a{
								&:after{
									display: none;
								}
							}
						}
						> a{
							font-size: 16px;
							color: $white;
							font-family: $montserrat;
							font-weight: 300;
							//padding: 35px 31.1px 37px 31.1px;
							padding: 35px 29.5px 37px 31.1px;
							display: block;
							position: relative;
							&:after{
								content: '';
								display: block;
								border-right: 2px solid $green;
								height: 16px;
								top: 38.5px;
								right: 0;
								position: absolute;

							}
						}
						> div{
							display: none;
							padding: 30px 32.156px 0 32.156px;
							position: absolute;
							top: 100%;
							left: 0;
							background-color: #102059;
							> ul{
								> li{
									display: inline-block;
									vertical-align: top;
									//width: 25%;
									width: 24.8%;
									margin-bottom: 24px;
									> a{
										color: #a9b3de;
										font-size: 16px;
										font-weight: 300;
										font-family: $montserrat;
										display: inline-block;
										padding-left: 15px;
										background-repeat: no-repeat;
										background-position: left top 7px;
										background-image: url('./../img/green_mark.png');
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

section.slider{
	padding: 0 5%;
	margin-top: -43px;
	> div.slides{
		height: 0;
		padding-bottom: 31%;
		width: 100%;
		position: relative;
		> ul{
			@include fill;
			> li{
				@include fill;
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
				div.slider_content{
					z-index: 2;
					position: absolute;
					bottom: 85px;
					left: 0;
					width: 100%;
					div.date{
						padding: 12px 19px;
						color: $white;
						background-color: $green;
						display: inline-block;
						font-size: 14px;
						font-family: $montserrat;
						margin-bottom: 35px;
					}
					div.text_desc{
						padding-bottom: 40px;
						border-bottom: 1px solid rgba(255,255,255,0.3);
						div.default_font{
							color: $white;
							font-family: $lato;
							strong{
								font-weight: 600;
							}
							p{
								text-align: justify;
								font-weight: 300;
							}
						}
					}
				}
			}
		}
		div.slider_cover{
			@include fill;
			background-repeat: repeat-x;
			background-position: left bottom;
			background-image: url('./../img/slider_cover.png');
			background-size: contain;
		}
		div.slider_nav{
			position: absolute;
			bottom: 39px;
			left: 0;
			width: 100%;
			div.slider_dots{
				> ul{
					> li{
						cursor: pointer;
						display: inline-block;
						vertical-align: top;
						border-radius: 50%;
						width: 16px;
						height: 0;
						padding-bottom: 12px;
						border: 2px solid rgba(255,255,255,0.3);
						margin-right: 14px;
						&:last-child{
							margin-right: 0;
						}
						&.on{
							background-color: $white;
						}
					}
				}
			}
		}
	}
}

aside.side_menu{
	position: relative;
	top: -85px;
    .widget_header{
        h3{
            font-size: 22px;
            line-height: 30px;
            font-family: "Montserrat", sans-serif;
        }
    }
	div.side_box{
		position: relative;
		div.side_width{
			padding: 29px 32px 0 32px;
		}
			.side_header{
				//padding-bottom: 29px;
                color: $white;
                h2{
                    color: $white;
                    font-size: 26px;
                    font-family: "Montserrat", sans-serif;
                }
                h1,h2,h3,h4,h5,h6,p{
                    text-transform: uppercase;
                }
                strong{
                    font-weight: 600;
                }
		}

		&.promotion{
			position: relative;
			background-color: #102059;
			color: $white;
			border-top: 3px solid #007ef5;
			div.star{
				padding: 0;
				width: 58px;
				height: 0;
				padding-bottom: 58px;
				@include blueGradient;
				position: absolute;
				bottom: 100%;
				right: 0;
				> div{
					background-position: center;
					background-repeat: no-repeat;
					background-image: url('./../img/star_icon.png');
					@include fill;
				}
			}
			p{
				color: #8f9cd2;
			}
			div.date{
				font-size: 14px;
				font-family: $montserrat;
				margin-bottom: 23px;
			}
			div.side_nav{
				margin-bottom: 29px;
				> div{
					display: inline-block;
					vertical-align: middle;
					width: 50%;
					> ul{
						> li{
							display: inline-block;
							vertical-align: top;
						}
					}
					&.dots{
						> ul{
							> li{
								&:last-child{
									margin-right: 0;
								}
								margin-right: 10px;
								cursor: pointer;
								width: 12px;
								height: 0;
								padding-bottom: 8px;
								border: 2px solid rgba(255,255,255,0.3);
								border-radius: 50%;
								&.on{
									background-color: $white;
								}
							}
						}
					}
					&.arrs{
						text-align: right;
						> ul{
							> li{
								margin-right: 20px;
								&:last-child{
									margin-right: 0;
								}
							}
						}
						img{
							cursor: pointer;
						}
					}
				}
			}
			div.text_desc{
				padding-bottom: 55px;
				div.default_font{
					strong{
						font-weight: 500;
					}
					p{
						font-weight: 300;
					}
				}
			}
			div.more{
				padding: 30px 0;
				border-top: 1px solid rgba(255,255,255,0.3);
			}
		}
		&.shortcuts{
			div.triangle_cover{
				z-index: 5;
				content: '';
				display: block;
				width: 21px;
				height: 18px;
				position: absolute;
				top: -3px;
				left: -1px;
				background-repeat: no-repeat;
				background-position: left top;
				background-image: url('./../img/side_triangle1.png');
			}
			border-top: 3px solid #007ef5;
			background-color: #172c7b;
			header{
				padding-bottom: 16px;
			}
			div.side_width{
				border-bottom: 1px solid rgba(255,255,255,0.3);
				padding-bottom: 32px;
			}
			nav.side_menu{
				margin-left: 29px;
				margin-right: 29px;
				> ul{
					> li{
						border-bottom: 1px solid rgba(255,255,255,0.3);
						padding: 16px 0;
						&:first-child{
							padding-top: 0;
						}
						a{
							font-size: 16px;
							color: #cad1ed;
							line-height: 24px;
							font-family: $lato;
						}
					}
				}
			}
		}
		&.link_side_box{
			&.green_box{
				border-top:3px solid #bfff8b;
				div.triangle_cover{
					z-index: 5;
					content: '';
					display: block;
					width: 21px;
					height: 18px;
					position: absolute;
					top: -3px;
					right: -1px;
					background-repeat: no-repeat;
					background-position: left top;
					background-image: url('./../img/side_triangle2.png');
				}
				div.more{
					background-color: rgba(97,189,85,0.5);
				}
			}
			&.blue_box{
				border-top:3px solid #6fb8fd;
				div.triangle_cover{
					z-index: 5;
					content: '';
					display: block;
					width: 21px;
					height: 18px;
					position: absolute;
					top: -3px;
					left: -1px;
					background-repeat: no-repeat;
					background-position: left top;
					background-image: url('./../img/side_triangle1.png');
				}
				div.more{
					background-color: rgba(28,136,238,0.5);
				}
			}
			width: 100%;
			height: 241px;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			position: relative;
			div.text_desc{
				div.default_font{
					color: $white;
					h1,h2,h3,h4,h5,h6,p{
						font-weight: 300;
						strong{
							font-weight: 600;
						}
					}
				}
			}
			div.more{
				padding: 21px 0;
				text-align: center;
				//background-color: rgba(0,0,0,0.1);
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
			}
		}
		&.organ{
			border-top: 3px solid #d6d8e1;
			background-color: $white;
			padding-bottom: 30px;
			header{
				border-bottom: 1px solid #d6d8e1;
				div.default_font{
					color: #102059;
				}
			}
			ul{
				margin-top: 2px;
				text-align: center;
				li{
					margin-bottom: 50px;
					width: 100%;
					&:last-child{
						margin-bottom: 0;
					}
					img{
						max-width: 100%;
						height: auto;
					}
				}
			}
		}
	}
	div.promotion_slides{
		position: relative;
		height: 392px;
		width: 100%;
		> ul{
			@include fill;
			> li{
				@include fill;
				display: none;
				&:first-child{
					display: block;
				}
				div.text_desc{
					height: 272px;
				}
			}
		}
	}
}

main{
	background-color: #f2f3f8;
	position: relative;
	div.overmain{
		width: 100%;
		height: 0;
		padding-bottom: 14%;
		background-color: #f2f3f8;
		position: absolute;
		bottom: 100%;
		left: 0;
		border-top: 1px solid #d7dae4;
		z-index: -1;
	}
}

section.important_info{
	//margin-top: 30px;
	width: 100%;
	height: 0;
	padding-bottom: 56%;
	position: relative;
	div.slider{
			> ul{
			@include fill;
			> li{
                a{
                    @include fill;
                    display: block;
                }
				.image{
                    @include fill;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                }
				div.slider_content{
					padding: 0 30px;
					position: absolute;
					bottom: 30px;
					position: absolute;
					left: 0;
					z-index: 3;
					div.date{
						padding: 12px 19px;
						color: $white;
						background-color:rgba(0, 0, 0, 0.5);
						display: inline-block;
						font-size: 14px;
						font-family: $montserrat;
						margin-bottom: 35px;
					}
					header{
						padding-bottom: 17px;
						margin-bottom: 17px;
						border-bottom: 1px solid rgba(255, 255, 255, 0.3);
						strong{
							font-weight: 600;
						}
					}
					div.important_box_header{
                        h2{
                            font-size: 22px;
                            line-height: 30px;
                            font-family: "Montserrat", sans-serif;
                        }
						color: $white;
					}
				}
			}
		}
		div.cover{
			@include fill;
			background-repeat: repeat-x;
			background-position: left bottom;
			background-image: url('./../img/slider_cover.png');
			background-size: contain;
		}
		div.slider_nav{
			//border-top: 3px solid $red;
			padding: 15px 30px;
			background-color: rgba(255,255,255,0.8);
			position: relative;
			div.row{
				> div{
					padding-left: 0;
					padding-right: 0;
				}
			}
			div.default_font{
				text-align: right;
				h1,h2,h3,h4,h5,h5,p{
					display: inline-block;
					color: $red;
					padding-top: 14px;
					padding-bottom: 14px;
					padding-left: 60px;
					background-repeat: no-repeat;
					background-position: left center;
					background-image: url('./../img/mark_icon.png');
				}
			}
			div.text-right{
				//padding-top: 20px;
					> ul{
						> li{
							width: 50%;
						}
					}
				}
			.slider_dots{
                button{
                    display: inline-block;
                    vertical-align: top;
                    width: 12px;
                    height: 0;
                    padding-bottom: 8px;
                    border: 2px solid #7883af;
                    margin-right: 10px;
                    border-radius: 50%;
                    cursor: pointer;
                    &:last-child{
                        margin-right: 0;
                    }
                    &.on{
                        background-color: #d94242;
                    }
                }
			}
			.slider_arrs{
                button{
                    display: inline-block;
                    vertical-align: top;
                    margin-right: 10px;
                    &:last-child{
                        margin-right: 0;
                    }
                }
				> ul{
					> li{

						img{
							cursor: pointer;
						}
						margin-right: 20px;

					}
				}
			}
		}
	}
}

a.more2{
	display: inline-block;
	color: #102059;
	border-radius: 50px;
	border: 2px solid #007ef5;
	padding: 21px 30px;
	font-size: 14px;
	font-family: $montserrat;
	font-weight: 600;
}

header.section_header{
	div.default_font{
		border-left: 3px solid #007ef5;
		padding-left: 30px;
		padding-top: 11px;
		padding-bottom: 11px;
		strong{
			font-weight: 600;
		}
		color: #102059;
	}
}

section.news{
	padding-top: 30px;
	div.news_list{
		padding-top: 30px;
		> ul{
			> li{
				display: inline-block;
				vertical-align: top;
				width: 49%;
				margin-right: 2%;
				margin-bottom: 2%;
				position: relative;
				&:hover{
					div.desc{
						div.text_desc{
							div.default_font{
								h1,h2,h3,h4,h5,h6{
									color: #007ef5;
									text-decoration: underline;
								}
							}
							div.more{
								a.more1{
									background-image: url('./../img/blue_arrow.png');
								}
							}
						}
					}
				}
				&:nth-child(2n){
					margin-right: 0;
				}
				&:nth-child(4n-3){
					background-color: $white;
					div.image{
						div.triangle_cover{
							background-image: url('./../img/news_triangle1.png');
						}
					}
				}
				&:nth-child(4n){
					background-color: $white;
					div.image{
						div.triangle_cover{
							background-image: url('./../img/news_triangle1.png');
						}
					}
				}
				&:nth-child(4n-2){
					background-color: #e8e9ef;
					div.image{
						div.triangle_cover{
							background-image: url('./../img/news_triangle2.png');
						}
					}
				}
				&:nth-child(4n-1){
					background-color: #e8e9ef;
					div.image{
						div.triangle_cover{
							background-image: url('./../img/news_triangle2.png');
						}
					}
				}
				div.image{
					height: 220px;
					width: 100%;
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;
					position: relative;
					div.cover{
						@include fill;
						background-repeat: repeat-x;
						background-position: left bottom;
						background-size: contain;
						background-image: url('./../img/slider_cover.png');
					}
					div.date{
						position: absolute;
						bottom: 20px;
						left: 27px;
						z-index: 3;
						color: $white;
						font-family: $montserrat;
						font-size: 14px;
					}
					div.triangle_cover{
						width: 26px;
						height: 23px;
						position: absolute;
						bottom: 0;
						left: 0;
						background-repeat: no-repeat;
						background-position: left bottom;
					}
				}
				div.desc{
					padding:20px 28px;
					div.text_desc{
						text-align: right;
						div.news_header{
							color: #5b627e;
                            h3{
                                font-size: 22px;
                                line-height: 30px;
                                font-family: "Montserrat", sans-serif;
                            }
							h1,h2,h3,h4,h5,h6{
								transition: 0.3s all ease;
								color: #102059;
							}
							text-align: left;
							height: 140px;
						}
					}
					div.more{
						padding: 20px 0 0 0!important;
						border-top: 1px solid #d9dbe2;
						a.more1{
							transition: 0.3s all ease;
							color: #5b627e;
						}
					}
				}
			}
		}
	}
}

section.partners{
	padding: 3% 0;
	background-color: $white;
	ul{
		text-align: center;
		li{
			display: inline-block;
			vertical-align: top;
			margin-right: 75px;
			img{
				height: 60px;
			}
			&:last-child{
				margin-right: 0;
			}
		}
	}
}

footer.footer{
	position: relative;
	div.overfooter{
		position: absolute;
		bottom: 0;
		left: 0;
		height: 50%;
		width: 100%;
		background-color: #16233a;
		z-index: -1;
	}
	div.footer_width{
		padding: 0 5%;
		div.main_footer{
			padding-top: 37px;
			border-top: 3px solid #0068c6;
			background-color: #102059;
			padding-bottom: 70px;
			.fh{
                font-size: 26px;
                color: $white;
                padding-bottom: 15px;
                margin-bottom: 25px;
                border-bottom: 1px solid rgba(255,255,255,0.1);
                strong{
                    font-weight: 600;
                }
			}
			div.footer_box{
				color: #cad1ed;
				a{
					color: #cad1ed;
				}
				nav.footer_menu{
					ul{
						> li{
							padding-bottom: 20px;
							&:last-child{
								padding-bottom: 0;
							}
							a{
								color: #cad1ed;
								font-size: 16px;
								padding-left: 15px;
								background-repeat: no-repeat;
								background-position: left center;
								background-image: url('./../img/green_mark.png');
							}
						}
					}
				}
			}
		}
	}
	div.footer_bottom{
		background-color: #16233a;
		padding: 25px 0;
        @media only screen and (max-width: 991px) {
            img{
                margin-left: 0 !important;
            }
        }
        .default_font{
            font-size: 10px !important;
        }
		ul{
			&.bottom_left{
                white-space: nowrap;
                @media only screen and (max-width: 991px) {
                    white-space: normal;
                }
				li{
					&:last-child{
						> div{
							border-right: none!important;
						}
					}
					> div{
						&.copyright{
							color: #f2f3f8;
							padding-left: 0;
							font-size: 13px;
							font-weight: 300;
							//padding-top: 13px;
							//padding-bottom: 13px;
							border-right: 1px solid #454f61;
						}
						&.default_font{
							border-right: 1px solid #454f61;
						}
					}
				}
			}
			li{
				display: inline-block;
				vertical-align: middle;
				> div{
					padding-left:15px;
					padding-right: 15px;
					&.default_font{
						color: #7685a0;
						font-family: $montserrat;
						font-size: 12px;
						line-height: 12px;
						> p{
							&.to_up{
								cursor: pointer;
							}
							padding: 15px 0;
							a{
								color: #7685a0;
								text-decoration: none;
								&:hover{
									text-decoration:none;
								}
							}
						}
					}
				}
			}
		}
		div.devs{
			> ul{
				text-align: right;
				> li{
					div.default_font{
						color: $white;
						p{
							font-weight: 300;
						}
					}
				}
			}
		}
	}
}

div.page{
	&.subpage{
		header.header{
			div.header_bottom{
				div.menu_box{
					top: 0;
					margin-top: 32px;
				}
			}
		}
		main.split_view{
			border-top: 1px solid #d7dae4;
			> div.overmain{
				display: none;
			}
			aside.side_menu{
				top: 30px;
			}
			div.main_content{
				div.more{
					padding: 45px 0;
				}
			}
			> div.container{
				> div.row{
					&:first-child{
						margin-bottom: 71px;
					}
				}
			}
		}
	}
}

section.top{
	padding: 30px 0;
	div.nav_way{
		> div{
			display: inline-block;
			vertical-align: top;
			font-family: $montserrat;
			&:first-child{
				margin-right: 12px;
			}
			&:last-child{
				> ul{
					li{
						&.active{
							> a{
								font-weight: 500;
								color: #172c7b;
							}
						}
						display: inline-block;
						vertical-align: top;
						margin-right: 12px;
						&:first-child{
							> a{
								background-image: none;
								padding-left: 0;
							}
						}
						&:last-child{
							margin-right: 0;
						}
						> a{
							font-size: 14px;
							color: #6c7390;
							padding-left: 22px;
							background-repeat: no-repeat;
							background-position: left center;
							background-image: url('./../img/nav_way_arr.png');
						}
					}
				}
			}
			> p{
				font-size: 14px;
				color: #6c7390;
			}
		}
	}
}

section.news_details{
	background-color: $white;
	margin-top: 30px;
	div.news_top{
		position: relative;
		width: 100%;
		height: 0;
		padding-bottom: 42.5%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		div.news_top_content{
			padding: 0 30px;
			position: absolute;
			bottom: 30px;
			position: absolute;
			left: 0;
			z-index: 3;
			div.date{
				padding: 12px 19px;
				color: $white;
				background-color:rgba(0, 0, 0, 0.5);
				display: inline-block;
				font-size: 14px;
				font-family: $montserrat;
				margin-bottom: 35px;
			}
			header.news_details_header{
				padding-bottom: 17px;
				margin-bottom: 17px;
				border-bottom: 1px solid rgba(255, 255, 255, 0.3);
                h1{
                    font-size: 30px;
                    line-height: 36px;
                    font-family: "Montserrat", sans-serif;
                    color: $white;
                }
				strong{
					font-weight: 600;
				}
			}
		}
		div.cover{
			@include fill;
			background-repeat: repeat-x;
			background-position: left bottom;
			background-image: url('./../img/slider_cover.png');
			background-size: contain;
		}
		div.triangle_cover{
			width: 26px;
			height: 23px;
			position: absolute;
			bottom: 0;
			left: 0;
			background-repeat: no-repeat;
			background-position: left bottom;
			background-image: url('./../img/news_triangle1.png');
		}
	}
	div.news_desc{
		padding: 27px;
		div.text_desc{
			padding-bottom: 77px;
			border-bottom: 1px solid #dddee4;
			div.default_font{
				//color: #5b627e;
				color:$font_color;
				p{
					font-weight: 400;
				}
				div.image{
					margin-bottom: 20px;
					height: 214px;
					width: 100%;
					background-size: contain;
					background-repeat: no-repeat;
					background-position: center;
				}
			}
		}
	}
	div.about_desc{
		padding: 0 27px 27px 27px;
		ul{
			li{
				display: inline-block;
				vertical-align: middle;
				&:first-child{
					margin-right: 30px;
				}
			}
		}
		div.default_font{
			&.text-right{
				padding-top: 21px;
				color: #5b627e;
				font-family: $montserrat;
				p{
					font-weight: 300;
				}
			}
		}
	}
	div.hidden_galery{
		padding:0 27px 0 27px;
		display: none;
	}
	a.more2{
		cursor: pointer;
	}
}

div.vertical_height{
	@include fill;
	> div{
		display: table;
		width: 100%;
		height: 100%;
		> div{
			display: table-cell;
			vertical-align: middle;
			height: 100%;
		}
	}
}

section.galery{
	padding-top: 30px;
}

.galery{
	div.galery_list{
		padding-top: 30px;
		> ul{
			li{
				width: 49%;
				margin-right: 2%;
				position: relative;
				display: inline-block;
				vertical-align: top;
				&:nth-child(2n){
					margin-right: 0;
				}
				div.image{
					width: 100%;
					height: 280px;
					background-repeat: no-repeat;
					background-size: cover;
					background-position: center;
					position: relative;
					> div.zoom_cover{
						transition: 0.3s all ease;
						opacity: 0;
						@include fill;
						background-color: rgba(23,44,123,0.7);
					}
				}
				div.title{
					padding: 20px 0;
                    color: #000000;
				}
				&:hover{
					> div.image{
						> div.zoom_cover{
							opacity: 1;
						}
					}
				}
			}
		}
	}
}

section.informations{
	padding-top: 30px;
	header.section_header{
		padding-bottom: 30px;
	}
	div.text_desc{
		padding: 0 30px;
		div.default_font{
			font-weight: 300;
			color: #5b627e;
			h1,h2,h3,h4,h5,h6{
				font-family: $montserrat;
				color: #102059;
				padding-bottom: 5px;
				strong{
					font-weight: 500;
				}
			}
		}
	}
	div.col-md-6{
		position: relative;
		&:first-child{
			&:after{
				content: '';
				display: block;
				height: 100%;
				position: absolute;
				top: 0;
				right: 0;
				border-right: 1px solid $white;
			}
		}
	}
	div.download{
		&:last-child{
			padding-bottom: 30px;
			border-bottom: 1px solid $white;
			margin-bottom: 30px;
		}
		header{
			margin-top: 30px;
			padding: 30px 0;
			border-top: 1px solid $white;
			div.default_font{
				padding-left: 20px;
				background-repeat: no-repeat;
				background-position: left center;
				background-image: url('./../img/green_mark.png');
				strong{
					font-weight: 500;
				}
				h1,h2,h3,h4,h5,h6{
					padding-bottom: 0;
					color: #102059;
				}
			}
		}
		div.download_links{
			ul{
				li{
					margin-bottom: 20px;
					&:last-child{
						margin-bottom: 0;
					}
					a{
						padding: 10px 0 8px 51px;
						background-repeat: no-repeat;
						background-position: left top;
						background-image: url('./../img/pdf_icon.png');
						display: inline-block;
						font-weight: 700;
						color: #5b627e;
						font-size: 16px;
						transition: 0.3s all ease;
						&:hover{
							text-decoration: underline;
							color: $green;
						}
					}
				}
			}
		}
	}
}

section.members{
	padding-top: 30px;
	header.section_header{
		padding-bottom: 30px;
	}
	div.members_list{
		padding: 0 30px;
		> ul{
			text-align: center;
			> li{
				position: relative;
				padding: 3% 3% 0 3%;
				display: inline-block;
				vertical-align: top;
				background-color: $white;
				width: 31%;
				margin-right: 3%;
				margin-bottom: 3%;
				&:nth-child(3n){
					margin-right: 0;
				}
				&:last-child{
					margin-right: 0;
				}
				div.image{
					width: 100%;
					height: 225px;
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;
				}
				div.name{
					padding: 25px 0;
					div.default_font{
						color: #5b627e;
						h1,h2,h3,h4,h5,h6{
							font-family: $lato;
							color: #102059;
							strong{
								font-weight: 700;
							}
						}
					}
				}
			}
		}
	}
}

section.members_details{
	padding-top: 30px;
	header.section_header{
		padding-bottom: 30px;
	}
	div.image{
		width: 230px;
		height: 219px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		margin-bottom: 30px;
	}
	div.contact_data{
		margin-bottom: 45px;
	}
	div.text_desc{
		div.default_font{
			color: #5b627e;
			p{
				strong{
					font-weight: 700;
				}
			}
		}
	}
	div.default_font{
		h1,h2,h3,h4,h5,h6{
			color: #102059;
			strong{
				font-weight: 500;
			}
		}
	}
	div.member_desc{
		header{
			padding: 23px 0;
			border-top: 1px solid $white;
			border-bottom: 1px solid $white;
			margin-bottom: 35px;
			div.default_font{
				padding-left: 20px;
				background-repeat: no-repeat;
				background-position: left center;
				background-image: url('./../img/green_mark.png');
				strong{
					font-weight: 500;
				}
				h1,h2,h3,h4,h5,h6{
					padding-bottom: 0;
				}
			}
		}
	}
}

@media only screen and (min-width: 1301px) and (max-width: 1500px) {
	//section.slider{padding: 0% 2.5%;}
	footer.footer div.footer_width{padding: 0% 2.5%;}
}

/*@media only screen and (min-width: 992px) and (max-width: 1300px) {
	section.slider{padding: 0%;}
	footer.footer div.footer_width{padding: 0%;}
}*/

@media only screen and (min-width: 1200px) and (max-width: 1366px) {
	.container{width: 1000px;}
	header.header div.header_top ul li > div{height: auto;}
	header.header div.header_top ul li.header_calendar > div{font-size: 10px; padding: 10px 31px 0 31px; height: 37px;}
	div.logo_box img{max-width: 100%; height: auto;}
	header.header div.header_top ul li.ue img{max-width: 100px; height: auto;}
	header.header div.header_top ul li.ue > div{height: 37px; padding-top: 7px;}
	header.header div.header_middle form div.holder input.input{height: 55px; font-size: 11px;}
	header.header div.header_middle div.short_menu{padding-top: 7px;}
	header.header div.header_middle div.short_menu nav.menu > ul > li a{font-size: 13px;}
	header.header div.header_bottom div.menu_box nav.menu > ul > li > a{font-size: 11px; padding: 15px 29.5px 15px 31.1px;}
	header.header div.header_bottom div.menu_box nav.menu > ul > li > a:after{top: 15px;}
	header.header div.header_bottom div.menu_box nav.menu > ul > li > div > ul > li{margin-bottom: 15px;}
	header.header div.header_bottom div.menu_box nav.menu > ul > li > div > ul > li > a{font-size: 11px; background-position: left top 4px;}
	header.header div.header_bottom div.menu_box nav.menu > ul > li > div{padding: 15px 32.156px 0 32.156px}

	section.slider{margin-top: -20px;}

	div.default_font{font-size: 13px; line-height: 26px;}
	div.default_font h1 {
		font-size: 38px;
		line-height: 44px;
	}
	  div.default_font h2 {
		font-size: 30px;
		line-height: 36px;
	}
	  div.default_font h3 {
		font-size:24px;
		line-height: 30px;
	}
	  div.default_font h4 {
		font-size: 20px;
	}
	  div.default_font h5 {
		font-size: 17px;
		line-height: 25px;
	}
	  div.default_font h6 {
		font-size: 15px;
		line-height: 22px;
	}
	div.text_desc div.default_font h1, div.text_desc div.default_font h2, div.text_desc div.default_font h3, div.text_desc div.default_font h4, div.text_desc div.default_font h5, div.text_desc div.default_font h6, div.text_desc div.default_font p, div.text_desc div.default_font a, div.text_desc div.default_font ul, div.text_desc div.default_font ol{
		padding-bottom: 10px;
	}
	aside.side_menu div.side_box.shortcuts nav.side_menu > ul > li a{font-size: 11px;}
	a.more1{font-size: 11px;}
	a.more2{font-size: 11px;}

	aside.side_menu div.side_box.shortcuts div.side_width{padding: 18px 32px 18px 32px;}
	aside.side_menu div.side_box.shortcuts header{padding-bottom: 12px;}
	aside.side_menu div.side_box.shortcuts nav.side_menu > ul > li{padding: 12px 0;}
	aside.side_menu div.side_box.link_side_box{height: 210px;}

	section.news div.news_list > ul > li div.image{height: 180px;}

	footer.footer div.footer_width div.main_footer div.footer_box nav.footer_menu ul > li{padding-bottom: 10px;}
	footer.footer div.footer_width div.main_footer div.footer_box nav.footer_menu ul > li a{font-size: 11px;}
	footer.footer div.footer_bottom ul li > div.default_font > p{font-size: 11px;}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	/*div.logo_box img{width: 100%; height: auto;}
	header.header div.header_bottom div.menu_box nav.menu > ul > li a{padding: 35px 24.4px 37px 24.5px; font-size: 13px;}
	header.header div.header_bottom div.menu_box nav.menu > ul > li div a{padding: 0; font-size: 13px!important;}
	section.slider > div.slides{padding-bottom: 500px;}

	header.header div.header_middle div.short_menu nav.menu > ul > li.bip{padding-left: 46px;}
	header.header div.header_middle div.short_menu nav.menu > ul > li a{font-size: 10px;}*/

	section.slider{padding: 0%;}
	footer.footer div.footer_width{padding: 0%;}

	.container{width: 994px;}
	header.header div.header_top ul li > div{height: auto;}
	header.header div.header_top ul li.header_calendar > div{font-size: 10px; padding: 10px 31px 0 31px; height: 37px;}
	div.logo_box img{max-width: 100%; height: auto;}
	header.header div.header_top ul li.ue img{max-width: 100px; height: auto;}
	header.header div.header_top ul li.ue > div{height: 37px; padding-top: 7px;}
	header.header div.header_middle form div.holder input.input{height: 55px; font-size: 11px;}
	header.header div.header_middle div.short_menu{padding-top: 7px;}
	header.header div.header_middle div.short_menu nav.menu > ul > li a{font-size: 12px;}
	header.header div.header_bottom div.menu_box nav.menu > ul > li > a{font-size: 11px; padding: 15px 29.5px 15px 31.1px;}
	header.header div.header_bottom div.menu_box nav.menu > ul > li > a:after{top: 15px;}
	header.header div.header_bottom div.menu_box nav.menu > ul > li > div > ul > li{margin-bottom: 15px;}
	header.header div.header_bottom div.menu_box nav.menu > ul > li > div > ul > li > a{font-size: 11px; background-position: left top 4px;}
	header.header div.header_bottom div.menu_box nav.menu > ul > li > div{padding: 15px 32.156px 0 32.156px}

	section.slider{margin-top: -20px;}

	div.default_font{font-size: 13px; line-height: 26px;}
	div.default_font h1 {
		font-size: 38px;
		line-height: 44px;
	}
	  div.default_font h2 {
		font-size: 30px;
		line-height: 36px;
	}
	  div.default_font h3 {
		font-size:24px;
		line-height: 30px;
	}
	  div.default_font h4 {
		font-size: 20px;
	}
	  div.default_font h5 {
		font-size: 17px;
		line-height: 25px;
	}
	  div.default_font h6 {
		font-size: 15px;
		line-height: 22px;
	}
	div.text_desc div.default_font h1, div.text_desc div.default_font h2, div.text_desc div.default_font h3, div.text_desc div.default_font h4, div.text_desc div.default_font h5, div.text_desc div.default_font h6, div.text_desc div.default_font p, div.text_desc div.default_font a, div.text_desc div.default_font ul, div.text_desc div.default_font ol{
		padding-bottom: 10px;
	}
	aside.side_menu div.side_box.shortcuts nav.side_menu > ul > li a{font-size: 11px;}
	a.more1{font-size: 11px;}
	a.more2{font-size: 11px;}

	aside.side_menu div.side_box.shortcuts div.side_width{padding: 18px 32px 18px 32px;}
	aside.side_menu div.side_box.shortcuts header{padding-bottom: 12px;}
	aside.side_menu div.side_box.shortcuts nav.side_menu > ul > li{padding: 12px 0;}
	aside.side_menu div.side_box.link_side_box{height: 210px;}

	section.news div.news_list > ul > li div.image{height: 180px;}

	footer.footer div.footer_width div.main_footer div.footer_box nav.footer_menu ul > li{padding-bottom: 10px;}
	footer.footer div.footer_width div.main_footer div.footer_box nav.footer_menu ul > li a{font-size: 11px;}
	footer.footer div.footer_bottom ul li > div.default_font > p{font-size: 11px;}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

}

@media only screen and (min-width: 320px) and (max-width: 767px) {
	section.news div.news_list > ul > li div.image{height: 0; padding-bottom: 100%;}
	section.news div.news_list > ul > li{width: 100%; margin-right: 0; margin-bottom: 15px;}
	section.news div.news_list > ul > li:last-child{margin-bottom: 0;}

	.galery div.galery_list > ul li{width: 100%; margin-right: 0; margin-bottom: 0;}
	.galery div.galery_list > ul li:last-child{margin-bottom: 0;}

	section.members div.members_list > ul > li{width: 100%; margin-right: 0; margin-bottom: 30px;}
	section.members div.members_list > ul > li:last-child{margin-bottom: 0;}
	section.members div.members_list > ul > li div.image{height: 0; padding-bottom: 100%;}

	section.informations div.download header div.default_font{padding-left: 0; background-image: none; text-align: center;}
	section.informations div.download div.download_links ul{text-align: center;}
	section.informations div.download div.download_links ul li a{padding: 51px 0 0 0; background-position: center top;}

	section.news_details div.news_desc div.default_font{overflow-x: scroll;}
}

@media only screen and (min-width: 320px) and (max-width: 992px) {
	header.header div.header_top ul li.header_calendar{display: none;}
	header.header div.header_top ul li.ue{width: 100%;}
	header.header div.header_top ul li.ue > div{padding-left: 0; border-left: none;}

	div.logo_box{position: relative;}
	div.logo_box img{height: 47px!important; width: auto!important;}
	.rwd_button{display: block;position: absolute; width: 30px; top: 15px; right: 0;}
	.rwd_button > span{display:block; width: 100%; height: 3px; background-color:#172c7b; margin-bottom: 3px;}
	header.header div.header_middle{padding: 12px 0 12px 0;}

	header.header div.header_bottom div.menu_box nav.menu > ul > li{width: 100%;}
	header.header div.header_bottom div.menu_box nav.menu > ul > li > a{padding: 0;}
	header.header div.header_bottom div.menu_box nav.menu > ul > li > div{width: 100%!important; padding: 0;}
	header.header div.header_bottom div.menu_box nav.menu > ul > li > div > ul > li{width: 100%;}

	aside.side_menu{top: 0;}
	div.star{display: none;}

	section.slider{padding: 0; margin-top: 0;}
	section.slider > div.slides{padding-bottom: 350px;}
	section.slider > div.slides > ul > li{background-position: center right;}

	section.important_info{padding-bottom: 400px;}

	section.partners ul li{width: 100%; margin-right: 0; margin-bottom: 30px;}

	section.informations div.col-md-6:first-child:after{display: none;}

	section.news_details div.news_top{height: 0; padding-bottom: 100%;}

	footer.footer div.footer_width div.main_footer{padding-bottom: 0;}
	footer.footer div.footer_bottom ul li{width: 100%;}
	footer.footer div.footer_bottom ul.bottom_left li > div.default_font{padding-top: 0; padding-left: 0; padding-right: 0; border:none;}
	footer.footer div.footer_width div.main_footer div.footer_box{margin-bottom: 40px;}
	footer.footer div.footer_bottom ul li > div{padding-left: 0; padding-right: 0;}
	footer.footer div.footer_bottom div.devs > ul{text-align: left;}
	footer.footer div.footer_width{padding: 0;}

	.side_menu div.side_box div.triangle_cover{display: none!important;}
	section.important_info div.slider div.slider_nav header{display: none;}
	section.important_info div.slider div.slider_nav div.text-right {text-align: left; padding-top: 0;}
	section.important_info div.slider div.slider_nav div.text-right .slider_arrs{text-align: right;}

	div.default_font{font-size: 13px; line-height: 26px;}
	div.default_font h1{font-size: 36px; line-height: 42px;}
	div.default_font h2{font-size: 25px; line-height: 30px;}
	div.default_font h3{font-size: 22px; line-height: 28px;}
	div.default_font h4{font-size: 20px; line-height: 25px;}
	div.default_font h5{font-size: 18px; line-height: 23px;}
	div.default_font h6{font-size: 15px; line-height: 20px;}

	div.page.christmas aside.side_menu div.side_box.shortcuts:before{display: none!important;}
	div.page.christmas aside.side_menu div.side_box.link_side_box.blue_box:before{display: none!important;}
	div.page.christmas aside.side_menu div.side_box.link_side_box.green_box:before{display: none!important;}
	div.page.christmas aside.side_menu div.side_box.organ:before{display: none!important;}

	header.header div.header_middle div.short_menu nav.menu > ul > li{display: block; background-image: none!important;}
	header.header div.header_middle div.short_menu nav.menu > ul > li:last-child a{padding-right: 21px;}
	header.header div.header_middle div.short_menu nav.menu > ul > li.availability a{padding-top: 0!important; margin-top: 12px; padding-bottom: 0!important;}
	header.header div.header_middle div.short_menu nav.menu > ul > li:last-child a:after{border-right: 2px solid #7daff8!important;}


}

section.schools{
	div.row{
		&:last-child{
			div.col-md-12{
				div.school_list{
					margin-bottom: 0;
					> ul{
						> li{
							&:last-child{
								padding-bottom: 0;
								padding-bottom: 0;
								border-bottom: none;
							}
						}
					}
				}
			}
		}
	}
	padding-top: 30px;
	header.section_header{
		margin-bottom: 50px;
	}
	header.scool_header{
		margin-bottom: 20px;
		div.default_font{
			color: $green;
			//text-align: center;
            h2{
                font-size: 26px;
                font-family: "Montserrat", sans-serif;
            }
		}
	}
	div.school_list{
		margin-bottom: 50px;
		> ul{
			//text-align: center;
			> li{
				margin-bottom: 30px;
				padding-bottom: 30px;
				border-bottom: 2px solid $white;
				&:last-child{
					margin-bottom: 0;
				}
				div.desc{
					margin-bottom: 15px;
					div.text_desc{
						div.default_font{
							h1,h2,h3,h4,h5,h6,p,a,ol,ul{
								padding-bottom: 10px;
								&:last-child{
									padding-bottom: 0;
								}
							}
							color: $dark_blue;
							a{
								color: $blue;
							}
						}
					}
					header{
						padding-bottom: 15px;
					}
					//text-align: center;
				}
				ul{
					text-align: center;
					li{
                        width: 31%;
						display: inline-block;
						margin-right: 3.5%;
                        vertical-align: top;
						&:nth-child(3n){
							margin-right: 0;
						}
						&:last-child{
							margin-right: 0;
						}
                        .image{
                            height: 0;
                            padding-bottom: 100%;
                            width: 100%;
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: cover;
                            position: relative;
                        }
					}
				}
			}
		}
	}
}
/*
$white:#ffffff;
$green:#52b945;
$red:#d94242;
$graphite:#5b5d62;
$dark_blue:#16233a;
$medium_blue:#102059;
$blue:#007ef5;
$light_blue:#7daff8;
$grey:#e8e9ef;
$font_color:#5b627e;
*/
div.page{
	&.mourning{
		header.header div.header_middle form div.holder input.submit{background-image: url('./../img/search_grey.png');}

		header.header div.header_bottom div.menu_box nav.menu{background-color: #1a1a1a;}
		header.header div.header_bottom div.menu_box nav.menu > ul > li:hover{background-color: rgba(94,94,94,0.5);}
		header.header div.header_bottom div.menu_box nav.menu > ul > li > a:after{border-right:2px solid rgba(94,94,94,0.5);}
		header.header div.header_bottom div.menu_box nav.menu > ul > li > div{background-color: rgba(94,94,94,1);}
		header.header div.header_bottom div.menu_box nav.menu > ul > li > div > ul > li > a{color: $white; background-image: url('./../img/white_mark.png');}
		header.header div.header_middle div.short_menu nav.menu > ul > li a:after{border-right: 2px solid #848484;}
		header.header div.header_bottom div.menu_box nav.menu > ul > li:hover{border-top: 2px solid $white;}
		header.section_header div.default_font{color: #1a1a1a; border-left: 3px solid #929292;}
		a.more1{background-image: url('./../img/grey_arr.png'); color: #929292;}
		a.more2{color: #1a1a1a; border: 2px solid #929292;}

		aside.side_menu div.side_box.shortcuts{background-color: #3c3c3c; border-top: 3px solid #929292;}
		aside.side_menu div.side_box.shortcuts nav.side_menu > ul > li a{color: #d5d5d5;}

		section.slider > div.slides > ul > li div.slider_content div.date{background-color: #3f3f3f;}
		section.important_info div.slider div.slider_nav{border-top: 3px solid #929292;}
		section.important_info div.slider div.slider_nav div.default_font h1, section.important_info div.slider div.slider_nav div.default_font h2, section.important_info div.slider div.slider_nav div.default_font h3, section.important_info div.slider div.slider_nav div.default_font h4, section.important_info div.slider div.slider_nav div.default_font h5, section.important_info div.slider div.slider_nav div.default_font h5, section.important_info div.slider div.slider_nav div.default_font p{color: #1a1a1a; background-image: url('./../img/mark_icon2.png');}
		aside.side_menu div.side_box.link_side_box div.more{background-color: rgba(0,0,0,0.5)!important;}
		aside.side_menu div.link_side_box a.more1{color: $white!important; background-image: url('./../img/white_arr.png')!important;}
		section.news{
			div.news_list{
				> ul{
					> li:hover{
						div.desc{
							div.text_desc{
								div.default_font{
									h1,h2,h3,h4,h5,h6{color: #1a1a1a;}
								}
								div.more{
									a.more1{
										background-image: url('./../img/grey_arr.png');
									}
								}
							}
						}
					}
				}
			}
		}

		footer.footer div.footer_bottom ul.bottom_left li > div.default_font{border-right: 1px solid #3c3c3c;}
		footer.footer div.footer_width div.main_footer div.footer_box a{color: #d5d5d5;}
		footer.footer div.footer_width div.main_footer{background-color: #3c3c3c; border-top: 3px solid #929292;}
		footer.footer div.footer_width div.main_footer div.footer_box{color: #d5d5d5;}
		footer.footer div.footer_width div.main_footer div.footer_box nav.footer_menu ul > li a{color: #d5d5d5; background-image: url('./../img/white_mark.png');}
		footer.footer div.footer_bottom{background-color: #1a1a1a;}
		footer.footer div.overfooter{background-color: #1a1a1a;}
		footer.footer div.footer_bottom ul li > div.default_font > p.to_up{color: #8e8e8e;}
		footer.footer div.footer_bottom ul li > div.default_font > p a{color: #8e8e8e;}
	}
	&.easter{
		header.header div.header_bottom div.menu_box nav.menu{background-color: #52b945;}
		header.header div.header_bottom div.menu_box nav.menu > ul > li:hover{background-color: #fdf513;}
		header.header div.header_bottom div.menu_box nav.menu > ul > li:hover a{color: $green;}
		header.header div.header_bottom div.menu_box nav.menu > ul > li > a:after{border-right:2px solid #fdf513;}
		header.header div.header_bottom div.menu_box nav.menu > ul > li > div{background-color: #fdf513;}
		header.header div.header_bottom div.menu_box nav.menu > ul > li > div > ul > li > a{color: $green; background-image: url('./../img/green_mark.png');}
		header.header div.header_middle div.short_menu nav.menu > ul > li a:after{border-right: 2px solid #52b945;}
		header.header div.header_bottom div.menu_box nav.menu > ul > li{border-top: 2px solid #52b945;}
		header.header div.header_bottom div.menu_box{top: 45px;}

		header.header div.header_bottom div.menu_box{position: relative;}
		header.header div.header_bottom div.menu_box:before{content: ''; display: block; width: 100px; height: 93px; background-repeat: no-repeat; background-position: center; background-image: url('./../img/egg_l.png'); position: absolute; top: -25px; left: -69px; z-index: -1;}
		header.header div.header_bottom div.menu_box:after{content: ''; display: block; width: 174px; height: 124px; background-repeat: no-repeat; background-position: center; background-image: url('./../img/egg_r.png'); position: absolute; top: -50px; right: -69px; z-index: -1;}

		header.section_header div.default_font{color: #102059; border-left: 3px solid #f4bd00;}
		a.more1{background-image: url('./../img/green_arrow.png'); color: $white;}
		a.more2{color: #1f2e64; border: 2px solid #52b945;}


		aside.side_menu div.side_box.shortcuts{background-color: #f4bd00; border-top: 3px solid #f4da00;}
		aside.side_menu div.side_box.shortcuts nav.side_menu > ul > li a{color: $white;}
		aside.side_menu div.link_side_box a.more1{color: $white!important; background-image: url('./../img/white_arr.png')!important;}

		section.important_info div.slider div.slider_nav{border-top: 3px solid #52b945;}
		section.important_info div.slider div.slider_nav div.default_font h1, section.important_info div.slider div.slider_nav div.default_font h2, section.important_info div.slider div.slider_nav div.default_font h3, section.important_info div.slider div.slider_nav div.default_font h4, section.important_info div.slider div.slider_nav div.default_font h5, section.important_info div.slider div.slider_nav div.default_font h5, section.important_info div.slider div.slider_nav div.default_font p{color: #52b945; background-image: url('./../img/mark_icon3.png');}
		/*section.news{
			div.news_list{
				> ul{
					> li:hover{
						div.desc{
							div.text_desc{
								div.default_font{
									h1,h2,h3,h4,h5,h6{color: #1a1a1a;}
								}
								div.more{
									a.more1{
										background-image: url('./../img/grey_arr.png');
									}
								}
							}
						}
					}
				}
			}
		}*/

		footer.footer div.footer_bottom ul.bottom_left li > div.default_font{border-right: 1px solid rgba(255,255,255,0.1);}
		footer.footer div.footer_width div.main_footer div.footer_box a{color: $white;}
		footer.footer div.footer_width div.main_footer{background-color: #52b945; border-top: 3px solid #52b945;}
		footer.footer div.footer_width div.main_footer div.footer_box{color: $white;}
		footer.footer div.footer_width div.main_footer div.footer_box nav.footer_menu ul > li a{color: $white; background-image: url('./../img/yellow_mark.png');}
		footer.footer div.footer_bottom{background-color: #459b3a;}
		footer.footer div.overfooter{background-color: #459b3a;}
		footer.footer div.footer_bottom ul li > div.default_font > p.to_up{color: $white;}
		footer.footer div.footer_bottom ul li > div.default_font > p a{color: $white;}
	}
	&.patriotic{
		header.header{margin-top: 4px; border-top:4px solid #ef1717;}
		header.header div.header_middle form div.holder input.submit{background-image: url('./../img/red_search_icon.png');}
		header.header div.header_bottom div.menu_box nav.menu{background-color: #ef1717;}
		header.header div.header_bottom div.menu_box nav.menu > ul > li:hover{background-color: $white;}
		header.header div.header_bottom div.menu_box nav.menu > ul > li:hover a{color: #ef1717;}
		header.header div.header_bottom div.menu_box nav.menu > ul > li > a:after{border-right:2px solid rgba(255,255,255,0.3);}
		header.header div.header_bottom div.menu_box nav.menu > ul > li > div{background-color: $white;}
		header.header div.header_bottom div.menu_box nav.menu > ul > li > div > ul > li > a{color: #ef1717; background-image: url('./../img/red_mark.png');}
		header.header div.header_middle div.short_menu nav.menu > ul > li a:after{border-right: 2px solid rgba(239,23,23,0.5);}
		header.header div.header_bottom div.menu_box nav.menu > ul > li{border-top: 2px solid #ef1717;}
		header.header div.header_bottom div.menu_box{top: 45px;}

		header.header div.header_bottom div.menu_box{position: relative;}

		header.section_header div.default_font{color: #102059; border-left: 3px solid #ef1717;}
		a.more1{background-image: url('./../img/red_arrow123.png'); color: $white;}
		a.more2{color: #1f2e64; border: 2px solid #ef1717;}

		section.slider > div.slides > ul > li div.slider_content div.date{background-color: #ef1717;}

		aside.side_menu div.side_box.shortcuts div.side_width{border-bottom: 1px solid #ef1717;}
		aside.side_menu div.side_box.shortcuts{background-color: $white; border-top: 3px solid #ef1717;}
		aside.side_menu div.side_box.shortcuts nav.side_menu > ul > li a{color: #848484;}
		aside.side_menu div.side_box.shortcuts nav.side_menu > ul > li{border-bottom: 1px solid rgba(225,228,234,0.8);}
		aside.side_menu div.side_box header div.default_font{color: #ef1717;}
		aside.side_menu div.side_box.link_side_box div.more a.more1{background-image: url('./../img/white_arr.png');}
		section.news{
			div.news_list{
				> ul{
					> li:hover{
						div.desc{
							div.text_desc{
								div.default_font{
									h1,h2,h3,h4,h5,h6{color: #ef1717;}
								}
								div.more{
									a.more1{
										background-image: url('./../img/grey_arr.png');
									}
								}
							}
						}
					}
				}
			}
		}
		section.partners{background-color: #f2f3f8;}
		footer.footer div.footer_bottom ul.bottom_left li > div.copyright{color: #83889d;}
		footer.footer div.footer_bottom div.devs > ul > li div.default_font{color: #83889d;}
		footer.footer{background-color: #f2f3f8;}
		footer.footer div.footer_width div.main_footer header div.default_font{color: #ef1717; border-bottom: 1px solid rgba(225, 228, 234, 0.8)}
		footer.footer div.footer_bottom ul.bottom_left li > div.default_font{border-right: 1px solid #d3d6de;}
		footer.footer div.footer_width div.main_footer div.footer_box a{color: #5b627e;}
		footer.footer div.footer_width div.main_footer{background-color: $white; border-top: 3px solid $white; border-bottom: 7px solid #ef1717;}
		footer.footer div.footer_width div.main_footer div.footer_box{color: #5b627e;}
		footer.footer div.footer_width div.main_footer div.footer_box nav.footer_menu ul > li a{color: #5b627e; background-image: url('./../img/red_mark.png');}
		footer.footer div.footer_bottom{background-color: #e8e9ef; position: relative; z-index: 5;}
		footer.footer div.overfooter{background-color: #e8e9ef; z-index: 4;}
		footer.footer div.footer_bottom ul li > div.default_font > p.to_up{color: #83889d;}
		footer.footer div.footer_bottom ul li > div.default_font > p a{color: #83889d;}
		footer.footer > div.footer_width{position: relative; z-index: 6;}
	}
	&.christmas{
		//header.header{margin-top: 4px; border-top:4px solid #ef1717;}
		//header.header div.header_middle form div.holder input.submit{background-image: url('./../img/red_search_icon.png');}
		header.header div.header_bottom div.menu_box nav.menu{background-image: url('./../img/christmas_menu_bg.jpg'); background-repeat: no-repeat; background-position: center; background-size: cover; position: relative; z-index: 2;}
		header.header div.header_bottom div.menu_box nav.menu:after{content: ''; display: block; width: 110%; left: -5%; height: 57px; background-repeat: no-repeat; background-position: center; background-image: url('./../img/header_snow.png'); top: -27px; position: absolute; background-size: contain;}
		header.header div.header_bottom div.menu_box:before{content: ''; display: block; width: 323px; height: 96px; background-position: center; background-repeat: no-repeat; background-image: url('./../img/trees_left.png'); position: absolute; top: -35px; left: -62px;}
		header.header div.header_bottom div.menu_box:after{content: ''; display: block; width: 470px; height: 127px; background-position: center; background-repeat: no-repeat; background-image: url('./../img/trees_right.png'); position: absolute; top: -59px; right: -44px;}
		header.header div.header_bottom div.menu_box nav.menu > ul > li:hover{background-color: $white;}
		header.header div.header_bottom div.menu_box nav.menu > ul > li:hover a{color: #ef1717;}
		//header.header div.header_bottom div.menu_box nav.menu > ul > li > a:after{border-right:2px solid rgba(255,255,255,0.3);}
		header.header div.header_bottom div.menu_box nav.menu > ul > li > div{background-color: $white;}
		header.header div.header_bottom div.menu_box nav.menu > ul > li > div > ul > li > a{color: #ef1717; background-image: url('./../img/red_mark.png');}
		//header.header div.header_middle div.short_menu nav.menu > ul > li a:after{border-right: 2px solid rgba(239,23,23,0.5);}
		header.header div.header_bottom div.menu_box nav.menu > ul > li{border-top: 2px solid #ef1717;}
		header.header div.header_bottom div.menu_box{top: 45px;}

		header.header div.header_bottom div.menu_box{position: relative;}

		header.section_header div.default_font{color: #102059; border-left: 3px solid #ef1717;}
		a.more1{background-image: url('./../img/green_arrow.png'); color: $white;}
		a.more2{color: #1f2e64; border: 2px solid #52b945;}

		background-image: url('./../img/christmas_bg.jpg');
		overflow: hidden;
		header.header{background-color: $white;}
		section.slider{position: relative; z-index: 5;}
		main{background-color: transparent;}
		div.overmain{
			width: 100%;
			height: 0;
			padding-bottom: 18%;
			background-color: $white;
			position: absolute;
			top: 0;
			left: 0;
			border-bottom: 1px solid #d7dae4;
			z-index: -1;
		}

		aside.side_menu{z-index: 6;}
		aside.side_menu div.side_box.shortcuts div.side_width{border-bottom: 1px solid rgba(255,255,255,0.2);}
		aside.side_menu div.side_box.shortcuts{background-color: #52b945; border-top: none; position: relative;}
		aside.side_menu div.side_box.shortcuts:before{content: ''; display: block; width: 110%; height: 52px; background-repeat: no-repeat; background-position: center; background-size: cover; position: absolute; top: -20px;; left: -5%; background-image: url('./../img/side_shortcuts_snow.png');}
		aside.side_menu div.side_box.link_side_box.blue_box:before{content: ''; display: block; width: 110%; height: 52px; background-repeat: no-repeat; background-position: center; background-size: cover; position: absolute; top: -20px;; left: -5%; background-image: url('./../img/side_blue_links_snow.png');}
		aside.side_menu div.side_box.link_side_box.green_box:before{content: ''; display: block; width: 110%; height: 52px; background-repeat: no-repeat; background-position: center; background-size: cover; position: absolute; top: -20px;; left: -5%; background-image: url('./../img/side_green_links_snow.png');}
		aside.side_menu div.side_box.organ:before{content: ''; display: block; width: 110%; height: 52px; background-repeat: no-repeat; background-position: center; background-size: cover; position: absolute; top: -20px;; left: -5%; background-image: url('./../img/side_last_snow.png');}
		aside.side_menu div.side_box.shortcuts nav.side_menu > ul > li a{color: $white;}
		aside.side_menu div.side_box.shortcuts nav.side_menu > ul > li{border-bottom: 1px solid rgba(255,255,255,0.2);}
		aside.side_menu div.side_box.shortcuts header div.default_font{color: $white;}
		aside.side_menu div.side_box.link_side_box div.more a.more1{background-image: url('./../img/white_arr.png');}
		section.news{
			div.news_list{
				> ul{
					> li:hover{
						div.desc{
							div.text_desc{
								div.default_font{
									h1,h2,h3,h4,h5,h6{color: #ef1717;}
								}
								div.more{
									a.more1{
										background-image: url('./../img/grey_arr.png');
									}
								}
							}
						}
					}
				}
			}
		}
		section.important_info:before{content: ''; display: block; width: 106%; height: 52px; top:-22px; left: -3%; background-repeat: no-repeat; background-position: center; background-size: contain; background-image: url('./../img/important_snow.png'); position: absolute; z-index: 10;}
		section.partners{background-color: transparent;}
		footer.footer div.footer_bottom ul.bottom_left li > div.copyright{color: #f2f3f8;}
		footer.footer div.footer_bottom div.devs > ul > li div.default_font{color: #f2f3f8;}
		footer.footer{background-color: transparent;}
		footer.footer div.footer_width div.main_footer header div.default_font{color: $white; border-bottom: 1px solid rgba(225, 225, 225, 0.2);}
		footer.footer div.footer_bottom ul.bottom_left li > div.default_font{border-right: 1px solid rgba(225,225,225,0.2);}
		footer.footer div.footer_width div.main_footer div.footer_box a{color: $white;}
		footer.footer div.footer_width div.main_footer{background-color: #52b945; border-top: 3px solid $white; border-bottom: 7px solid #52b945; position: relative;}
		footer.footer div.footer_width div.main_footer:before{content: ''; display: block; width: 102%; height: 52px; background-repeat: no-repeat; background-position: center; background-size: cover; position: absolute; top: -21px;; left: -1%; background-image: url('./../img/footer_snow.png');}
		footer.footer div.footer_width div.main_footer div.footer_box{color: $white;}
		footer.footer div.footer_width div.main_footer div.footer_box nav.footer_menu ul > li a{color: $white; background-image: url('./../img/red_mark.png');}
		footer.footer div.footer_bottom{background-color: #d51515; position: relative; z-index: 5;}
		footer.footer div.overfooter{background-color: #d51515; z-index: 4;}
		footer.footer div.footer_bottom ul li > div.default_font > p.to_up{color: #f2f3f8;}
		footer.footer div.footer_bottom ul li > div.default_font > p a{color: #f2f3f8;}
		footer.footer > div.footer_width{position: relative; z-index: 6;}
	}
}

div.rwd_menu{
	&.rwd_patriotic{
		background-color: #ef1717;
		nav.menu > ul > li a{background-image: url('./../img/white_mark.png');}
		div.holder input.submit{background-image: url("./../img/red_search_icon.png");}

	}
	&.rwd_christmas{
		background-color: #d51515;
	}
	&.rwd_easter{
		background-color: #f4bd00;
	}
	&.rwd_mourning{
		background-color: #1a1a1a;
		div.holder input.submit{background-image: url('./../img/search_grey.png');}
		nav.menu > ul > li a{background-image: url('./../img/white_mark.png');}
	}
}

section.breaking_news{
	margin: 15px 0;
	background-color: $white;
	//background-color: rgba(255,255,255,0.8);
	/*div.slider_nav{
		border-top: 3px solid $red;
		padding: 15px;
		position: relative;
		div.default_font{
			h1,h2,h3,h4,h5,h5,p{
				display: inline-block;
				color: $red;
				padding-top: 14px;
				padding-bottom: 14px;
				padding-left: 60px;
				background-repeat: no-repeat;
				background-position: left center;
				background-image: url('./../img/mark_icon.png');
			}
		}
	}*/
	> div{
		display: inline-block;
		vertical-align: top;
		&.mark_icon{
			width: 10%;
			height: 77px;
			background-repeat: no-repeat;
				background-position: center;
				background-image: url('./../img/mark_icon.png');
		}
		&.breaking_news_padding{
			width: 90%;
			padding-right: 15px;
		}
	}
	ul#bn{
		li{
			display: inline-block;
			margin-right: 15px;
			p{
				font-size: 26px;
    			font-family: "Montserrat", sans-serif;
				color: #d94242;
				padding-top: 14px;
				padding-bottom: 14px;
				padding-left: 60px;
				font-weight: 700;
			}
		}
	}
/* liScroll styles */

.tickercontainer { /* the outer div with the black border */
	width: 100%;
	//min-height: 50px;
	margin: 0;
	padding: 0;
	overflow: hidden;
	text-align: center;
}
/* that serves as a mask. so you get a sort of padding both left and right */
.tickercontainer .mask {
	position: relative;
	width: 100%;
	overflow: hidden;
}
ul.newsticker { /* that's your list */
position: relative;
list-style-type: none;
margin: 0;
padding: 0;

}
/* important: display inline gives incorrect results when you check for elem's width */
ul.newsticker li {
	float: left;
	margin: 10px;
	padding: 0;
}

ul.er-controls{
	list-style: none;
	display: inline-block;
	padding: 0;
}
ul.er-controls > li{
	display: inline-block;
	padding: 0 10px;
	background: #f0f0f0;
	margin: 5px;
	border-radius: 5px;
	height: 40px;
	line-height: 40px;
}
ul.er-controls > li:hover{
	background: #f5f5f5;
	cursor: pointer;
}
}
div.hidden_files{
	padding: 0 27px 27px 27px;
	header.section_header{
		padding-bottom: 30px;
	}
	div.download{
		&:last-child{
			padding-bottom: 0;
			margin-bottom: 0;
		}
		header{
			margin-top: 30px;
			padding: 30px 0;
			border-top: 1px solid $white;
			div.default_font{
				padding-left: 20px;
				background-repeat: no-repeat;
				background-position: left center;
				background-image: url('./../img/green_mark.png');
				strong{
					font-weight: 500;
				}
				h1,h2,h3,h4,h5,h6{
					padding-bottom: 0;
					color: #102059;
				}
			}
		}
		div.download_links{
			ul{
				li{
					margin-bottom: 20px;
					&:last-child{
						margin-bottom: 0;
					}
					a{
						padding: 10px 0 8px 51px;
						background-repeat: no-repeat;
						background-position: left top;
						background-image: url('./../img/pdf_icon.png');
						display: inline-block;
						font-weight: 700;
						color: #5b627e;
						font-size: 16px;
						transition: 0.3s all ease;
						&:hover{
							text-decoration: underline;
							color: $green;
						}
					}
				}
			}
		}
	}
}

.declaration{
    padding-top: 25px;
}

.declaration {
    .default_font {
        color: #4b5063;
        h1,h2,h3,h4,h5,h6{
            margin-bottom: 30px;
            color: #102059;
        }
    }
}

.declaration {
    .default_font {
        p{
            margin-bottom: 30px;
        }
    }
}
.declaration {
    .default_font {
        ul{
            list-style: disc;
            padding-left: 18px;
            margin-bottom: 30px;
            color: #007ef5;
        }
    }
}
.declaration {
    .default_font {
        ol{
            margin-bottom: 30px;
        }
    }
}
.declaration{
    .default_font {
        a{
            margin-bottom: 30px;
            color: #007ef5;
        }
    }
}
.declaration {
    .default_font {
        ul {
            li {
                ul, p{
                    margin-bottom: 0;
                }
            }
        }
    }
}
.declaration {
    .default_font {
        ul {
            li {
                p{
                    color: #4b5063;
                }
            }
        }
    }
}

.go_to_declaration{
    text-transform: uppercase;
    color: #7685a0;
    &:hover{
        color: #7685a0;
    }
}

#bn{
    white-space: nowrap !important;
}
#bn >li {
    float: none !important;
}

.subpage{
    .section_header{
        h1{
            font-size: 26px;
            font-family: "Montserrat", sans-serif;
        }
    }
}

.font-size-panel {
	button {
		background:none;
		border:0;
		padding:0!important;
		margin:0 0 0 10px!important;
	}
}

.slider_dots {
	> ul {
		display:inline-block;
	}
}

.important_info {
	.slider_dots {
		display:inline-block;
	}
}

.slider_start_stop_button {
	display:inline-block;
	margin-left:30px;

	button {
		background:none;
		border:0;
	}

	img {
		width:20px;
	}
}
